<template>
  <div class="desafiosSection">
    <animated-component animationType="left">
      <div class="header">
        <img src="../../../images/Goal.webp" />
        <div class="title">DESAFIOS</div>
      </div>
    </animated-component>

    <animated-component animationType="left">
      <div class="tContainer">
        <div>
          <div class="subtitle">
            Os desafios do #Hack2022 para João Pessoa são:
          </div>
        </div>
        <div class="memberWrapper">
          <div class="imageWrapper">
            <img src="../../../images/accessibilidade_surdos.webp" />
          </div>

          <div class="subWrapper">
            <p class="name">
              Como tornar o evangelho e o discipulado mais acessível para
              surdos?
            </p>
            <p class="role">
              2,1 milhões dos brasileiros são surdos ou escutam muito pouco, 32%
              deles não foram escolarizados. O acesso dos surdos ao evangelho,
              estudos bíblicos e discipulado são limitados devido à falta de
              intérpretes nas igrejas e da tradução de conteúdo para língua
              brasileira de sinais. Ainda não existe uma bíblia cristã traduzida
              em Libras.
            </p>
          </div>
        </div>
        <div class="memberWrapper">
          <div class="imageWrapper">
            <img src="../../../images/acessibilidade_autistas.webp" />
          </div>

          <div class="subWrapper">
            <p class="name">
              Como tornar o evangelho e o discipulado mais acessível para
              autistas?
            </p>
            <p class="role">
              Estima-se que quase 2% da população brasileira tem autismo.
              Entretanto, as limitações sociais, cognitivas e sensoriais do
              autista ainda é pouco compreendida pelas pessoas. Nas igrejas,
              faltam adaptações de ambientes e de conteúdos para esse público.
            </p>
          </div>
        </div>
      </div>
    </animated-component>
  </div>
</template>
<script setup></script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.desafiosSection {
  background-color: #d9d9d9;
  background-position: center;
  background-size: contain;
  padding: 100px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex: 1;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 40px;
    min-width: 300px;

    img {
      height: 35px;
      width: 35px;
      margin-right: 10px;
    }

    .title {
      font-size: 30px;
      font-family: 'Montserrat', sans-serif;
      color: #555555;
      font-weight: 800;
    }
  }

  .tContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;

    max-width: 1080px;

    .subtitle {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Montserrat', sans-serif;
      color: #555555;
      font-weight: 400;
      font-size: 24px;
    }

    .memberWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .subWrapper {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
      }

      .imageWrapper {
        img {
          height: 100%;
          max-width: 220px;
          object-fit: contain;
        }
        margin-bottom: 10px;
      }

      .name {
        font-weight: 800;
        font-size: 24px;
        color: #f14620;
        max-width: 550px;
        font-family: 'Montserrat', sans-serif;
        text-align: justify;
      }

      .role {
        font-size: 14px;
        color: #1e1e1e;
        font-weight: 600;
        max-width: 550px;
        font-family: 'Montserrat', sans-serif;
        text-align: justify;
      }

      .description {
        font-size: 12px;
        opacity: 0.8;
        max-width: 80%;
      }
    }
  }
}

@media (max-width: 800px) {
  .desafiosSection {
    .tContainer {
      .subtitle {
        font-size: 18px;
      }
      .memberWrapper {
        max-width: 90%;
        .imageWrapper {
          img {
            display: none;
          }
        }
        .name {
          font-size: 18px;
        }
        .role {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
