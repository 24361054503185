<template>
  <div class="splashArea">
    <div class="background">
      <div class="image"></div>
    </div>
    <div class="gradient"></div>
    <div class="aboutSection">
      <div class="container">
        <transition appear name="fade1">
          <h2 class="supporttext">#HACK@GORDON</h2>
        </transition>

        <transition appear name="fade">
          <div class="container">
            <h1 class="maintext">2022</h1>
          </div>
        </transition>

        <transition appear name="fade2">
          <p>Jenks 406 (Hybrid on Discord)</p>
        </transition>
      </div>

      <div class="footer">
        <div class="logo" style="margin-right: unset" @click="openUrl('https://hack.indigitous.org/')">
          <img src="../../../images/indigitous-logo-landscape.webp" />
        </div>
        <div class="logo" style="margin-left: unset" @click="openUrl('https://www.gordon.edu/cel')">
          <img src="../../../images/cel_logo.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// Imports.
import { ref } from 'vue';

let now = ref(new Date());
setInterval(() => {
  now.value = new Date();
}, 1000);

const openUrl = url => {
  window.open(url, '_blank');
};
</script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.splashArea {
  background-color: #1A1A1A;
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  h2 {
        font-size: 90px;
        text-transform: uppercase;
        color: #ff8d6c;
        font-family: 'Montserrat', sans-serif;
        font-weight: 100;
        text-align: center;
  }

  p {
        font-size: 30px;
        text-transform: uppercase;
        color: #FDFF74;
        font-family: 'Montserrat', sans-serif;
        font-weight: 100;
        text-align: center;
        margin-bottom: 16px;
  }

  .maintext {
      font-size: 160px;
      color: #ff8d6c;
      font-family: 'Montserrat', sans-serif;
      font-weight: 800;
  }
    
  .supporttext {
      font-size: 75px;
      font-family: 'Montserrat', sans-serif;
      font-weight: 800;
  }

  .background {
    transform-style: preserve-3d;
    overflow: hidden;
    will-change: transform;
    position: absolute;
    display: flex;
    height: 80vh;
    animation: slideshow 40s linear infinite;

    /* .image {
      width: 100vw;
      height: 100%;
      background-image: url('../../../images/BG-landing.webp');
      background-size: 100%;
      background-position: 50% 42%;
      background-attachment: scroll;
      overflow: hidden;
    } */


  }

  .gradient {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .navBar {
    padding: 20px;
    margin-right: 40px;

    .container {
      display: flex;
      justify-content: center;

      .link {
        display: flex;
        padding: 15px 20px;
        align-items: center;
        border-radius: 40px;
        transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #fff;
        text-decoration: none;
        font-size: 16px;

        &:hover {
          cursor: pointer;
          background-color: hsla(0, 0%, 100%, 0.1);
        }

        &:active {
          background-color: hsla(0, 0%, 100%, 0.2);
        }
      }

      .connect {
        position: absolute;
        right: 20px;
        box-shadow: inset 0 0 0 1px #fff;
      }
    }
  }

  .aboutSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

    .container {
      display: flex;
      width: 1800px;
      max-width: 90%;
      margin-right: auto;
      margin-left: auto;
      flex-direction: column;
      align-items: center;
      text-align: center;
      color: #fff;
      gap: 20px;

      .description {
        max-width: 750px;
        font-weight: bold;
      }

      .mintingCountdownWrapper {
        padding: 20px;
        border: 2px solid white;
        border-radius: 10px;
        .mintingTitle {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 20px;
        }
      }

      .image {
        max-width: 450px;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .logo {
        width: 900px;
        margin-bottom: 40px;
        max-width: 100%;
        vertical-align: middle;
        display: inline-block;
        opacity: 1;
      }


      .location {
        font-weight: 800;
        color: #efe974;
        margin-top: -24px;
        margin-left: 170px;
      }

      .mintingWrapper {
        min-width: 200px;
        padding: 20px 30px;
        background-image: linear-gradient(180deg, #ffd915, #ffa41c);
        color: #000;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
        text-decoration: none;
        display: flex;

        .space {
          margin-left: 10px;
        }
      }
    }

    .downCaret {
      position: absolute;
      color: #fff;
      animation: downCaret 1100ms infinite;
      cursor: pointer;
      transition: all 100ms ease;
      width: 100%;
      display: flex;
      justify-content: center;

      .link {
        color: white;
        width: 100%;
        text-align: center;
      }

      &:hover {
        transform: scale(1.05);
      }

      &:active {
        transform: scale(0.95);
      }
    }

    .footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 20px;
      background-color: #d9d9d9;
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 100px;
      box-sizing: border-box;

      .logo {
        width: 240px;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 0px;
        cursor: pointer;
        transition: all 500ms ease;

        &:active {
          transform: scale(0.9);
        }

        img {
          width: 180px;
        }
      }

      .link {
        display: flex;
        padding: 15px 20px;
        align-items: center;
        border-radius: 10px;
        transition: background-color 200ms cubic-bezier(0.215, 0.61, 0.355, 1);
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        background-color: transparent;
        border: none;
        height: fit-content;
        white-space: nowrap;

        &:hover {
          cursor: pointer;
          background-color: hsla(0, 0%, 100%, 0.1);
        }

        &:active {
          background-color: hsla(0, 0%, 100%, 0.2);
        }
      }
    }

    @keyframes downCaret {
      0% {
        bottom: 9vh;
      }
      50% {
        bottom: 7vh;
      }
      100% {
        bottom: 9vh;
      }
    }
  }
}

@media (max-width: 600px) {
  .splashArea .aboutSection .container h2 {
    font-size: 18px;
  }

  .splashArea .aboutSection .container .location {
    margin-left: 118px;
  }
  .splashArea .aboutSection .container .mintingWrapper {
    font-size: 4vw;
  }

  .splashArea .aboutSection .container .logo {
    display: none;
  }

  .splashArea .aboutSection .container .image img {
    width: 73%;
  }
}

@media (max-width: 450px) {
  .splashArea .aboutSection .container h2 {
    font-size: 20px;
    margin-left: -20px;
  }
  .splashArea .aboutSection .container .location {
    margin-left: 154px;
  }

  .splashArea .aboutSection .container .image img {
    width: 99%;
  }
}

@media (max-width: 390px) {
  .splashArea .aboutSection .container h2 {
    font-size: 18px;
    margin-left: -37px;
  }
  .splashArea .aboutSection .container .location {
    margin-left: 128px;
  }
}
</style>

<style scoped>
/* Fade animation */
.fade-enter-active,
.fade1-enter-active,
.fade2-enter-active,
.fade3-enter-active {
  transition: all 1000ms ease;
}

.fade-enter-from,
.fade1-enter-from,
.fade2-enter-from,
.fade3-enter-from {
  opacity: 0;
  transform: translateY(50px);
}

.fade1-enter-active {
  transition-delay: 400ms;
}

.fade2-enter-active {
  transition-delay: 800ms;
}

.fade3-enter-active {
  transition-delay: 800ms;
}
</style>
