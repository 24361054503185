<template>
  <div class="faqSection">
    <div class="container">
      <animated-component>
        <div class="heading">
          <img src="../../../images/hashtag.webp" />
          <div>FAQ</div>
        </div>
      </animated-component>
      <div class="dropdownWrapper" v-for="item in faq" :key="item.title">
        <animated-component animationType="right">
          <DropdownItem class="faq" :title="item.title" :description="item.description" />
        </animated-component>
      </div>
    </div>
  </div>
</template>
<script setup>
import DropdownItem from './DropdownItem.vue';

const faq = [
  {
    title: 'What is a Hackathon?',
    description: ['A combinação das palavras “hack” (desenvolver programas) e “marathon” (maratona),  um hackathon é um evento para pessoas de tecnologia e indústrias relacionadas criarem soluções para desafios específicos. As pessoas se reúnem, formam equipes em torno de um problema ou ideia e desenvolvem colaborativamente uma solução única.']
  },
  {
    title: 'Para quem é o Hackthon?',
    description: [
      'O #HACK dá as boas-vindas a qualquer pessoa que queira colaborar em projetos de tecnologia focados em missões com impacto de longo prazo. Embora nosso trabalho se concentre em várias plataformas de tecnologia, você não precisa ser um programador para se envolver. Precisamos de desenvolvedores, missionários, designers, fotógrafos, escritores, gerentes de projeto, especialistas em marketing,  pensadores criativos de todos os tipos! A habilidade mais importante é a capacidade de trabalhar em equipe e encontrar maneiras de resolver problemas. Além disso, apesar do #HACK ser explicitamente cristão, os participantes de qualquer (ou nenhuma) religião são bem vindos.'
    ]
  },
  {
    title: 'Por que participar desse Hackathon?',
    description: [
      'O #HACK João Pessoa, organizado pela comunidade Faithtech João Pessoa em parceria com a Indigitous, reúne pessoas com o desejo de levar o evangelho a novas pessoas, lugares e espaços. Como um evento de voluntários, o #HACK oferece aos participantes a oportunidade de atender às necessidades da missão, da igreja e da sociedade em escala local e global. Aprendemos uns com os outros, fazemos novos amigos e criamos o impacto para o Reino por meio de um sprint de inovação.'
    ]
  },
  {
    title: 'Quando será o evento?',
    description: [
      'O #Hack João Pessoa será do dia 1 a 25 de novembro. Teremos encontros durante à noite para abertura, orientações, mentoria e encerramento, mas cada equipe pode se organizar no dia e horário que considerar melhor. Veja mais detalhes no calendário acima e no nosso servidor discord.'
    ]
  },
  {
    title: 'Onde é o evento?',
    description: [
      'O #Hack será online, as equipes poderão se reunir em qualquer local, online ou presencial, como acharem melhor. A apresentação dos resultados será presencial. O endereço será divulgado no nosso servidor discord.'
    ]
  },
  {
    title: 'Precisa morar em João Pessoa para participar do #Hack?',
    description: [
      'Por ser um evento online, os participantes poderão estar em qualquer lugar do mundo. Basta se comunicar em português ou contar com a ajuda de um intérprete.'
    ]
  },
  {
    title: 'Qual o valor da inscrição?',
    description: [
      'A inscrição é gratuita.'
    ]
  },
  {
    title: 'Quantas horas são necessárias para participar do Hackathon?',
    description: [
      'É recomendado que cada pessoa participe dos encontros de orientação e mentoria (cerca de 5 horas) e colabore com o time na construção da solução (cerca de 30 horas).'
    ]
  },
  {
    title: 'O que é esperado na entrega dos projetos?',
    description: [
      'Cada equipe deve entregar protótipos funcionais de serviços ou produtos e uma apresentação contextualizando o desafio, os problemas priorizados e o desenho da solução. É necessário que o protótipo permita realizar testes com usuários e comunique o conceito da solução. Você pode utilizar vídeos para apresentar a navegação do protótipo, isso facilitará o entendimento pela equipe de avaliadores.'
    ]
  },
  {
    title: 'Quais os critérios de avaliação dos projetos?',
    description: [
      'Cada projeto será avaliado nos critérios de: (1) Impacto para Reino: Esta solução tem um impacto no reino? Promove os propósitos de Deus conforme comunicados na Bíblia? (2) Atendimento ao desafio: O quanto o projeto resolve o problema apresentado no desafio escolhido? (3) Inovação: Esta é uma abordagem original ou única? A equipe fez algo diferente na forma como eles abordaram o desafio? (4) Viabilidade: Quão viável é isso no mundo real? Esta solução é implementável? Quão difícil seria escalar? (5) Completude:  O projeto apresentou um protótipo funcional com um mínimo produto viável?'
    ]
  },
  {
    title: 'Qual a premiação do Hackathon?',
    description: [
      'A equipe que apresentar melhor resultado, segundo critérios de avaliação, receberá uma premiação simbólica, a ser divulgada no dia.'
    ]
  },
  {
    title: 'O que acontece após o Hackathon?',
    description: [
      'As equipes e organizações envolvidas poderão dar continuidade ao projeto no laboratório da comunidade Faithtech João Pessoa, onde poderão juntos serem acompanhados no desenvolvimento da solução. Após validação da solução, o projeto pode ser internalizado e sustentado por uma organização, ou a depender do modelo de negócio, pode ser transformado em uma startup. Todos os participantes do Hackathon são bem vindos na comunidade Faithtech João Pessoa. Nos encontramos mensalmente para discutir a intercessão entre a fé e tecnologia e trabalhamos colaborativamente em oportunidades do laboratório.'
    ]
  },
];

</script>
<style scoped lang="scss">
.faqSection {
  background-color: black;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 0px;
  margin: auto;
  
  .imageContainer {
    max-width: 600px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .container {
    width: 100%;
    padding: 40px;
    max-width: 1600px;

    .heading {
      font-size: 32px;
      font-weight: 700;
      text-transform: uppercase;

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;

      img {
        height: 35px;
        width: 35px;
        object-fit: contain;
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 800px) {
  .faqSection {
    grid-template-columns: 1fr;

    .container {
      .heading {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 600px) {
  .faqSection .container .dropdownWrapper .faq {
    margin: 20px 0;
  }
}
</style>
